<template>
  <div class="student-fee-details">
    <v-row justify="center">
      <v-col md="6">
        <div v-if="feeDetails">
          <!-- user details -->
          <v-list-item>
            <v-list-item-avatar
              ><v-img :src="feeDetails.user.image"></v-img
            ></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                feeDetails.user.full_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                feeDetails.user.username
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                feeDetails.user.room.sections
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- next due date card -->
          <v-card color="primary" class="mx-4 my-2">
            <v-card-text class="pa-0">
              <v-list-item>
                <v-list-item-icon class="align-self-center"
                  ><v-icon color="white">mdi-clock</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title class="white--text"
                    >Next Payment due on</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="white--text font-weight-bold text-h6 text-wrap"
                    ><span v-if="feeDetails.due_date">{{
                      moment(feeDetails.due_date).format("ddd, Do MMM, YYYY")
                    }}</span
                    ><span v-else class="red--text"
                      >Not Set</span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon class="align-self-center" v-if="!currentUser.is_student && (currentUser.is_admin || feeDetails.user.room.edit)"
                  ><v-btn
                    icon
                    @click="
                      createEditFeeDetailsSheet.editId = feeDetails.user.id;
                      createEditFeeDetailsSheet.visible = true;
                    "
                    ><v-icon color="white">mdi-pencil</v-icon>
                  </v-btn></v-list-item-icon
                >
              </v-list-item>
            </v-card-text>
          </v-card>
          <!-- balance calculation -->
          <v-card class="mx-4" elevation="0">
            <v-card-text class="pa-0">
              <v-list-item class="px-1">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-medium text-h6"
                    >Total Fees</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="ma-0 font-weight-bold text-h6"
                  >₹ {{ feeDetails.total_fee }}/-</v-list-item-action
                >
              </v-list-item>
              <v-list-item class="px-1">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-medium text-h6 ma-0"
                    >Total Paid</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="font-weight-bold text-h6 ma-0"
                  >₹ {{ feeDetails.total_paid }}/-</v-list-item-action
                >
              </v-list-item>
              <v-divider class="my-1"></v-divider>
              <v-list-item class="px-1">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-medium text-h6"
                    >Total Balance</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="font-weight-bold text-h6 ma-0"
                  >₹
                  {{
                    feeDetails.total_fee - feeDetails.total_paid
                  }}/-</v-list-item-action
                >
              </v-list-item>
            </v-card-text>
          </v-card>
        </div>
        <div class="mt-4" v-if="feeDetails">
          <p class="mx-4 text-h6 font-weight-bold">Payment History</p>
          <intract-smart-list
            ref="fee-transactions-smart-list"
            paginated
            :endpoint="transactionsEndpoint"
          >
            <template v-slot:list-item="{ item: transaction }">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    transaction.student.full_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    moment(transaction.transaction_date).format(
                      "lll"
                    )
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                  v-html="TextHelper.linkify(transaction.description)"
                  >
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    >Mode of Payment:
                    {{
                      paymentModes.find(
                        (m) => m.value == transaction.transaction_mode
                      ).title
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <div style="display: flex; flex-direction: row">
                    <p class="ma-0 mr-1 font-weight-bold text-h6 primary--text">
                      ₹ {{ transaction.paid_amount }}
                    </p>
                    <intract-drop-down-menu
                      v-if="currentUser.is_admin"
                      :options-list="getDropdownOptions(transaction)"
                    />
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
            </template>
            <template v-slot:paginated-no-items-found
              >No transactions found</template
            >
          </intract-smart-list>
        </div>
      </v-col>
    </v-row>
    <!-- student fee details edit sheet -->
    <intract-create-edit-sheet
      key="fee-details-create-edit-sheet"
      ref="fee-details-create-edit-sheet"
      :title="
        createEditFeeDetailsSheet.editId
          ? 'Edit Fee Details'
          : 'Create Fee Details'
      "
      description="Manage your students' fee details with just few clicks"
      persistent
      :getObject="finalOperationsOnStudentFeeDetailsObject"
      :visible="createEditFeeDetailsSheet.visible"
      @close="
        createEditFeeDetailsSheet.selectedUser = null;
        createEditFeeDetailsSheet.visible = false;
        createEditFeeDetailsSheet.editId = null;
      "
      :fields="feeDetailsFormFields"
      :endpoint="endpoints.studentFeesDetailViewSet"
      :edit-id="createEditFeeDetailsSheet.editId"
      :data-object="createEditFeeDetailsSheet.obj"
      :create-success-message="createEditFeeDetailsSheet.createSuccessMessage"
      :edit-success-message="createEditFeeDetailsSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditFeeDetailsSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- student fee details edit sheet -->
    <intract-create-edit-sheet
      key="fee-transaction-create-edit-sheet"
      ref="fee-transaction-create-edit-sheet"
      :title="
        createEditFeeTransactionSheet.editId
          ? 'Edit Fee Transaction'
          : 'Create Fee Transaction'
      "
      description="Keep track of the transactions happening between the student and your institution"
      persistent
      :visible="createEditFeeTransactionSheet.visible"
      @close="
        createEditFeeTransactionSheet.visible = false;
        createEditFeeTransactionSheet.editId = null;
      "
      :fields="feeTransactionFormFields"
      :endpoint="endpoints.feeTransactionViewSet"
      :edit-id="createEditFeeTransactionSheet.editId"
      :data-object="createEditFeeTransactionSheet.obj"
      :getObject="finalOperationsOnFeeTransactionObject"
      :create-success-message="
        createEditFeeTransactionSheet.createSuccessMessage
      "
      :edit-success-message="createEditFeeTransactionSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditFeeTransactionSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <transition name="slide-y-reverse-transition">
      <v-btn
        class="extended-fab non-focused"
        color="primary"
        v-if="!isLoading && !currentUser.is_student && (currentUser.is_admin || feeDetails && feeDetails.user.room.edit)"
        dark
        right
        bottom
        fixed
        fab
        @click="createEditFeeTransactionSheet.visible = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>New Transaction
      </v-btn>
      <v-btn
        class="extended-fab non-focused"
        color="primary"
        v-if="currentUser.is_student && preferences && preferences.enabled"
        dark
        right
        bottom
        fixed
        fab
        :to="{ name: 'FeePreferences' }"
      >
        <v-icon class="mr-1">mdi-information</v-icon>Payment Details
      </v-btn>
    </transition>
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this fee transaction?"
      description="This action cannot be undone. This will also send a notification to the student."
      @successCallback="deleteFeeTransaction"
      @failureCallback="showDeleteDialog = false; toBeDeletedTransaction=null;"
      delete-dialog
    />
  </div>
</template>
<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractSmartList from "@components/generics/IntractSmartList";
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import { mapActions } from "vuex";
export default {
  name: "StudentFeeDetails",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions ],
  components: {
    IntractSmartList,
    IntractCreateEditSheet,
    IntractDropDownMenu,
  },
  data() {
    return {
      TextHelper, 
      moment,
      feeDetails: null,
      showDeleteDialog: false,
      toBeDeletedTransaction: null,
      preferences: null,
      createEditFeeDetailsSheet: {
        selectedUser: null,
        visible: false,
        createSuccessMessage: "Fee Details were created successfully!",
        editSuccessMessage: "Fee Details were edited successfully!",
        editId: null,
        obj: {
          user: null,
          institution: null,
          total_paid: null,
        },
      },
      createEditFeeTransactionSheet: {
        visible: false,
        createSuccessMessage: "Fee Transaction was created successfully!",
        editSuccessMessage: "Fee Transaction was edited successfully!",
        editId: null,
        obj: {
          student: null,
          institution: null,
          admin: null,
        },
      },
    };
  },
  computed: {
    feeDetailsFormFields() {
      return {
        total_fee: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          input: "number",
          min: 0,
          label: "Total Fees",
          helper: "The total amount of fees for the student",
          prependIcon: "mdi-currency-inr",
          md: 6,
          required: true,
        },
        due_date: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          min: new Date(),
          label: "Due Date",
          clearable: true,
          helper:
            "The student will start getting reminders a few days prior to this date",
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.createEditFeeDetailsSheet.obj.due_date).isAfter(
                moment()
              ) ||
              "Due Date should not be before current date.",
          ],
        },
      };
    },

    appBarOptions(){
      return {
        actions: [
          ...(!this.currentUser.is_student && ( this.currentUser.is_admin || (this.feeDetails && this.feeDetails.user.room.edit))) ? 
            [
              {
                id: 1,
                title: "Edit Fee Details",
                icon: "mdi-pencil",
                onClick: () => {
                  this.createEditFeeDetailsSheet.visible = true;
                  this.createEditFeeDetailsSheet.editId = this.feeDetails.user.id;
                },
              },
            ] : [], 
        ]
      };
    },

    feeTransactionFormFields() {
      return {
        paid_amount: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          input: "number",
          min: 0,
          label: "Amount Paid",
          helper: "The amount the student has paid",
          prependIcon: "mdi-currency-inr",
          required: true,
          customRules: [
            (v) =>
              !v ||
              v <= this.feeDetails.total_fee - this.feeDetails.total_paid ||
              "Amount cannot be greater than total pending balance",
          ],
          md: 6,
        },
        // transaction_date: {
        //   fieldType: CreateEditFieldTypes.DATEPICKER,
        //   textModel: null,
        //   label: "Payment Date",
        //   required: true,
        //   prependIcon: "mdi-calendar",
        //   max: new Date(),
        //   clearable: true,
        //   helper:
        //     "The actual date when the amount was received.",
        //   md: 6,
        //   value: new Date(),
        //   customRules: [
        //     (v) =>
        //       !v ||
        //       moment(this.createEditFeeTransactionSheet.obj.transaction_date).isBefore(
        //         moment().add(1,'days')
        //       ) ||
        //       "Payment date cannot be after current date.",
        //   ],
        // },
        next_due_date: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          prependIcon: "mdi-calendar",
          min: new Date(),
          label: "Next Due Date",
          clearable: true,
          helper:
            "The student will start getting reminders a few days prior to this date",
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(
                this.createEditFeeTransactionSheet.obj.next_due_date
              ).isAfter(moment()) ||
              "Due Date should not be before current date.",
          ],
        },
        transaction_mode: {
          fieldType: CreateEditFieldTypes.SELECT,
          prependIcon: "mdi-cash",
          items: this.paymentModes,
          itemValue: "value",
          itemText: "title",
          label: "Mode of Payment",
          required: true,
          md: 6,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Description",
          prependIcon: "mdi-pencil-circle",
          md: 6,
          max: 500,
        },
      };
    },
    transactionsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.feeTransactionViewSet, [
        "student=" + this.$route.params.studentId,
        "ordering=-transaction_date",
      ]);
    },
    paymentModes() {
      return [
        { title: "Cash", value: 1 },
        { title: "Cheque", value: 2 },
        { title: "Bank Transfer", value: 3 },
        { title: "Online Payment", value: 4 },
        { title: "UPI", value: 5 },
      ];
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),
    finalOperationsOnStudentFeeDetailsObject(obj) {
      if (obj.due_date) obj.due_date = moment(obj.due_date).format();
      return obj;
    },

    finalOperationsOnFeeTransactionObject(obj) {
      if (obj.next_due_date) obj.next_due_date = moment(obj.next_due_date).format();
      if (obj.transaction_date) obj.transaction_date = moment(obj.transaction_date).format();
      return obj;
    },
    async getStudentFeeDetails() {
      var url =
        this.endpoints.studentFeesDetailViewSet +
        this.$route.params.studentId +
        "/";
      this.feeDetails = await this.api.call(this.essentials, url);
    },

    getDropdownOptions(transaction) {
      var self = this;
      return [
        // {
        //   obj: transaction,
        //   value: 1,
        //   text: "Edit",
        //   icon: "mdi-pencil",
        //   type: "edit",
        //   action() {
        //     self.createEditFeeTransactionSheet.editId = transaction.id;
        //     self.createEditFeeTransactionSheet.visible = true;
        //   },
        // },
        {
          obj: transaction,
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedTransaction = transaction;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async deleteFeeTransaction() {
      var url =
        this.endpoints.feeTransactionViewSet+ this.toBeDeletedTransaction.id + "/";
      this.showDeleteDialog = false;
      this.toBeDeletedTransaction = null;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Fee Transaction was successfully deleted!",
          type: "success",
        });
        this.onRefresh();
      }
    },
    
    async onRefresh() {
      console.log("onrefresh");
      await this.getStudentFeeDetails();
      this.createEditFeeDetailsSheet.obj.user =
        this.createEditFeeTransactionSheet.obj.student = Number(
          this.$route.params.studentId
        );
      this.createEditFeeDetailsSheet.obj.total_paid =
        this.feeDetails.total_paid;
      this.createEditFeeDetailsSheet.obj.institution =
        this.createEditFeeTransactionSheet.obj.institution =
          this.currentInstitution.id;
      this.createEditFeeTransactionSheet.obj.admin = this.currentUser.id;
      // refresh transactions
      var transactionsList = this.$refs["fee-transactions-smart-list"];
      if (transactionsList) transactionsList.getItems();

      EventBus.$emit("student_fee_details__updated");
    },

    async getInstitutionFeePreferences() {
      var url = this.endpoints.institutionFeePreferences + this.currentInstitution.id + "/";
      this.preferences = await this.api.call(this.essentials, url);
    },
  },
  beforeDestroy() {
    this.resetAppBarOptions();
  },
  created() {
    this.onRefresh();
    if(this.currentUser.is_student)
      this.getInstitutionFeePreferences();
    // this.setAppBar();
  },
};
</script>